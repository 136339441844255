html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  margin: 0;
  background-image: linear-gradient(to right, rgb(69, 184, 251), rgb(109, 135, 250), rgb(154, 83, 250));
}


.dailogMain {
  align-items: center !important;
}

.custom-select {
  font-family: none !important;
  font-weight: none !important;
  font-size: 1.5rem !important;
  line-height: none !important;
  letter-spacing: none !important;
  color: white !important;
  box-sizing: none !important;
  position: relative !important;
  cursor: none !important;
  display: none !important;
  display: none !important;
  display: none !important;
  display: inline-flex !important;

  align-items: none;
  border-radius: 4px;
}

.css-siyejb {
  display: none !important;
  display: none !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: none !important;
  -ms-flex-pack: end !important;
  -webkit-justify-content: none !important;
  justify-content: center !important;
  margin-top: 0 !important;
  align-items: center !important;
}

.css-sdz1fu {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding: 15px;
  margin: 2px;
}

.errormsg {
  display: flex;
  justify-content: center;
  color: red;
  font-size: larger;
}

.successmsg {
  display: flex;
  justify-content: center;
  color: green;
  font-size: larger;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

.formCss {
  margin-bottom: 5px;
}

.loginMain {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  margin: auto;
  border-radius: 15px;
  width: 556px;
  height: 75vh;
  border: 3px solid #ebdfdf;
  background-color: #f3f6f9;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
}

.sign {
  display: flex;
  align-content: center;
  align-items: center;
  padding: 15px;
  margin: 17px;
  border-radius: 11px;
  justify-content: space-around;
  font-size: larger;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.singcolour {
  display: flex;
  align-content: center;
  color: black;
  align-items: center;
  padding: 15px;
  margin: 17px;
  border-radius: 11px;
  justify-content: space-around;
  font-size: xx-large;
}

.loginpage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
}

.maildiv {
  display: flex;
}

.signDiv {
  display: flex;
}

.search {
  display: flex;
  justify-content: end;

}

.search-input {
  padding: 8px;
  margin: 10px;
  border-radius: 6px;
  font-size: large;
  background-color: azure;
  font-family: revert-layer;
}

.lift-box {
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* flex-wrap: wrap; */
  /* flex-direction: column; */
  /* border: solid antiquewhite; */
  border-radius: 30px;
  background-color: white;
  margin: 70px;
  box-shadow: 2px 2px #8b747452
}

.addiconcss {
  margin-right: 10px;
  display: flex;
  align-items: center;
  align-items: center;
  border-radius: 50%;
  padding: 15px;
  background-color: #f7f7f7;
  border: 3px solid #f2f2f2;
  text-align: center;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.titalClass {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  font-size: x-large;
}

.titalClassD {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-size: x-large;
}

.buttonTowerClass {
  padding: 14px;
  color: white;
  border-radius: 12px;
  margin: 20px;
  background-image: linear-gradient(to right, rgb(69, 184, 251), rgb(109, 135, 250), rgb(154, 83, 250));
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.buttonTowerClassD {
  display: flex !important;
  padding: 14px;
  color: white;
  border-radius: 12px;
  margin: 20px;
  background-image: linear-gradient(to right, rgb(69, 184, 251), rgb(109, 135, 250), rgb(154, 83, 250));
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.buttonLiftName {
  padding: 14px;
  color: white;
  border-radius: 7px;
  background-image: linear-gradient(to right, rgb(69, 184, 251), rgb(109, 135, 250), rgb(154, 83, 250));
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.l1FloorPosition {
  padding: 40px;
  width: 45px;
  /* color: white; */
  /* border-color: green; */
  border-radius: 15px;
  box-shadow: 0.5px 0.5px 0.5px 1px #5FFF7D;
  /* background-image: linear-gradient(to right, rgb(69, 184, 251), rgb(109, 135, 250), rgb(154, 83, 250)); */
}

.cardDiv {
  margin: auto;
  width: 50%;
  padding: 10px;
  border: 3px solid green;
}

.l1FloorPositionOffline {

  padding: 40px;
  width: 45px;
  /* color: white; */
  /* border-color: green; */
  border-radius: 15px;
  box-shadow: 0.5px 0.5px 0.5px 1px #FF2C2C;
}

/* #f5f6f7 */
.liftHeading {
  display: flex;
  align-items: center;
  text-align: center;
  /* padding: 10px; */
  align-content: center;
  font-size: x-large;
  justify-content: space-evenly;
}

.css-97lofm {
  margin: 0 0 0 0;
  height: none !important;
  padding: 2px !important;
}

.liftPosition {
  display: flex;
  font-size: 52px;
  font-family: 'Hanken Grotesk';
  align-content: center;
  justify-content: space-around;
  align-items: center;
  width: 27px;
}

.l1Diection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.l2Diection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.liftPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.liftPartDetails1 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: x-large;
  padding: 20px;
  flex-direction: row;
  font-family: math;
}

.liftPartDetails2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: x-large;
  padding: 20px;
  flex-direction: row;
  font-family: math;
}

.liftPartDetails3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: x-large;
  padding: 20px;
  flex-direction: row;
  font-family: math;
}

.mainLiftPartDetails {
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: nowrap; */

  padding: 14px;
  margin: auto;
  border-radius: 10px;
  background-color: #f7f7f7;
  width: 80%;
  border: 3px solid #f2f2f2;
  text-align: center;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.liftDoorStatus {
  /* padding: 40px;

  border-radius: 15px;
  box-shadow: 0.5px 0.5px 0.5px 1px #5FFF7D; */

  /* padding: 11px; */
  padding: 5px;
  width: 120px;
  align-items: center;
  /* color: white; */
  /* border-color: green; */
  border-radius: 6px;
  box-shadow: 0.5px 0.5px 0.5px 1px #5FFF7D;
}

.liftDoorStatusOffline {
  /* padding: 40px;

  border-radius: 15px;
  box-shadow: 0.5px 0.5px 0.5px 1px #5FFF7D; */

  /* padding: 11px; */
  padding: 5px;
  width: 120px;
  align-items: center;
  /* color: white; */
  /* border-color: green; */
  border-radius: 6px;
  box-shadow: 0.5px 0.5px 0.5px 1px #FF2C2C;
}

.imgIcon {
  border-radius: 50%;
  width: 50px;
  box-shadow: 0.2px 0.2px 1.5px 0.5px #18609c;
  background-color: white;
}

.blank {
  display: flex;
  margin: 32px;
}

.callLift {
  display: flex;
  /* flex-direction: row; */
  align-content: center;
  justify-content: center;
  align-items: center;
  /* padding: 25px; */
  background-color: #f7f7f7;
  width: 50%;
  margin: 48px auto 10px auto;
  border-radius: 15px;
  border: 3px solid #f2f2f2;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.loginButton {
  display: flex;
  border: none;
  color: white;
  padding: 9px 53px;
  text-align: center;
  font-size: 30px;
  border-radius: 10px;
  margin-top: 65px;
  background-image: linear-gradient(to right, rgb(69, 184, 251), rgb(109, 135, 250), rgb(154, 83, 250));
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.errorMessage {
  display: flex;
  color: rgb(211, 49, 49);
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 49px;
  font-size: x-large;
}

.b1Button {
  display: flex;
  border: none;
  color: white;
  padding: 1px 24px;
  text-align: center;
  font-size: 35px;
  border-radius: 20px;
  margin: 21px;
  background-image: linear-gradient(to right, rgb(69, 184, 251), rgb(109, 135, 250), rgb(154, 83, 250));
}

.direction {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 30px 0 30px 0;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upDiv {
  display: flex;
}

.downDiv {
  display: flex;
}

.door {
  margin-top: 8px;
  max-width: 35px;
  /* width: 36px; */
  height: auto;
}

.my-data-grid .custom-data-grid .MuiDataGrid-cell {
  border: 1px solid #ccc;
  padding: 8px;
}

.my-data-grid .custom-data-grid .MuiDataGrid-colCellTitle,
.my-data-grid .custom-data-grid .MuiDataGrid-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}